var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image_path)?_c('v-img',{attrs:{"src":item.image_path,"width":"30"}}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'Y')?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" ส่งสินค้าที่แลกเเล้ว ")]):_vm._e(),(item.status === 'C')?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" ยกเลิกการแลกสินค้า ")]):_vm._e(),(item.status === 'W')?_c('span',{staticStyle:{"color":"#de9307"}},[_vm._v(" รอการการตรวจสอบ ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }